import React, { useState } from 'react';
import {ABtnPrimary, ABtnPrimaryPlan, BtnPrimary, GlobalBackgroundColors, GlobalColors} from '../../styles/GlobalStyles';
import {
  Ico,
  Info,
  Price,
  PriceDesc,
  SubTitle,
  Title,
  ContCardPrice,
  ContInfo, ContBtnPlan, ContCircleUp, SubTitle2, ContaierButton
} from './styles';
import icoPhone from '../../images/ico-phone.svg';
import icoBell from '../../images/ico-bell.svg';
import icoPlus from '../../images/ico-plus.svg';
import { navigate } from 'gatsby';

const BtnLocal = ({ onClick, children }) => {
  return (
    <ABtnPrimaryPlan onClick={onClick}>
      {children}
    </ABtnPrimaryPlan>
  );
};

export const CardPlan = ({
    type, name, title, subTitle, info1, info2, info3, info4, 
    info5, info6, icon5 = 1, icon6 = 1, price, link = '', service = [], personalData = [], DetalleTexts,
    btnText = 'Contratar ahora', planButton = false,
  }) => {
  const [stService, setStService] = useState(service);

  const handleClick = () => {
    localStorage.setItem('myServices', JSON.stringify(stService));
    localStorage.setItem('price_plan', price);
    localStorage.setItem('name_plan', name);
    const combinedState = { ...stService, ...personalData, price: price, name: name };
    navigate('/pay', { state: combinedState })
  };

  return (
    <ContCardPrice
      bgColor={type === 1 ? GlobalBackgroundColors.bgPrimary : GlobalBackgroundColors.bgTertiary}
    >
      <ContCircleUp
        /*top={type === 1 ? 0 : '90px'}*/
        top={0}
        opacity={type === 1 ? 0.5 : 0.1}
        bgColor={type === 1 ? GlobalBackgroundColors.bgColorHeaderBorder : GlobalColors.colorMenuMobilePrimary}
      />
      <Title
        color={type === 1 ? GlobalColors.colorPrimary : GlobalColors.colorLight}
      >
        {title}
      </Title>
      {type == 2?
        <Title
        color={type === 1 ? GlobalColors.colorPrimary : GlobalColors.colorLight}
      >
        {name}
      </Title>
      :
      <Title></Title>
      }
      
      <SubTitle
        color={type === 1 ? GlobalColors.colorPrimary : GlobalColors.colorLight}
      >
        {subTitle}
      </SubTitle>

      <ContInfo>
        <Ico><img src={icoPhone} alt={''}/></Ico>
        <Info
          color={type === 1 ? GlobalColors.colorPrimary : GlobalColors.colorLight}
        >{info1}</Info>
      </ContInfo>

      <ContInfo>
        <Ico><img src={icoBell} alt={''}/></Ico>
        <Info
          color={type === 1 ? GlobalColors.colorPrimary : GlobalColors.colorLight}
        >{info2}</Info>
      </ContInfo>

      <ContInfo>
        <Ico><img src={icoPlus} alt={''}/></Ico>
        <Info
          color={type === 1 ? GlobalColors.colorPrimary : GlobalColors.colorLight}
        >{info3}</Info>
      </ContInfo>

      {info4 ? (
        <ContInfo>
          <Ico><img src={icoPlus} alt={''}/></Ico>
          <Info
            color={type === 1 ? GlobalColors.colorPrimary : GlobalColors.colorLight}
          >{info4}</Info>
        </ContInfo>
      ) : null}

      {info5 ? (
        <ContInfo>
          {icon5 ? (
            <Ico><img src={icoPlus} alt={''}/></Ico>
          ) : <Ico/>}
          <Info
            color={type === 1 ? GlobalColors.colorPrimary : GlobalColors.colorLight}
          >{info5}</Info>
        </ContInfo>
      ) : null}

      {info6 ? (
        <ContInfo>
          {icon6 ? (
            <Ico><img src={icoPlus} alt={''}/></Ico>
          ) : <Ico/>}
          <Info
            color={type === 1 ? GlobalColors.colorPrimary : GlobalColors.colorLight}
          >{info6}</Info>
        </ContInfo>
      ) : null
      }


      {/*{stService.map((item, index) => {
        return (
          <ContInfo key={index}>
            <CheckboxMyS
              label={item.label}
              checked={item.value}
              fontColor={type === 1 ? GlobalColors.colorText : GlobalColors.colorBtnTextPrimary}
              borderColor={type === 1 ? GlobalBackgroundColors.bgTertiary : GlobalBackgroundColors.bgQuaternary}
              bgColorChecked={type === 1 ? GlobalBackgroundColors.bgTertiary : GlobalBackgroundColors.bgQuaternary}
              onChange={() => onCheck(index)}
            />
          </ContInfo>
        );
      })}*/}
      {type === 2 ? 
      (<ContInfo>
        <Info
          color={type === 1 ? GlobalColors.colorPrimary : GlobalColors.colorLight}
        >
          Detalle:  
        </Info>
      </ContInfo>)
      :
      <Info></Info>
      }
      {type === 2 ? 
      (
        <ContInfo>
          <Info color={type === 1 ? GlobalColors.colorPrimary : GlobalColors.colorLight} >
            {DetalleTexts !== undefined?
            DetalleTexts.map(DetalleText => (
              <li>
                {DetalleText}
              </li>
            ))
            :
            <li></li>
            }  
          </Info> 
        </ContInfo>
      )
      :
      <Info></Info>
      }
      <ContaierButton>
      <ContBtnPlan>
        {link && planButton === false
          ? (<ABtnPrimary
              /*cover
              bg={GlobalBackgroundColors.bgContentLight}*/
              to={link}
              exit={{length: 0.5, state: {
                plan: name, title: title, price: price, services: stService,
                name: personalData.name,
                last_name: personalData.last_name,
                second_lastname: personalData.second_lastname,
                email: personalData.email,
                phone: personalData.phone,
              }}}
              entry={{delay: 0.5, state: {
                plan: name, title: title, price: price, services: stService,
                name: personalData.name,
                last_name: personalData.last_name,
                second_lastname: personalData.second_lastname,
                email: personalData.email,
                phone: personalData.phone,
              }}}
              state={{plan: name, title: title, price: price, services: stService,
                name: personalData.name,
                last_name: personalData.last_name,
                second_lastname: personalData.second_lastname,
                email: personalData.email,
                phone: personalData.phone,
              }}
            >
              {btnText}
            </ABtnPrimary>)
          : link && planButton === true ? (
            <BtnLocal onClick={handleClick}>
              {btnText}
            </BtnLocal>
          ) :
          (<ABtnPrimary
              cover
              bg={GlobalBackgroundColors.bgContentLight}
              to={'/registrate'}
              target="_self"
            >
              {btnText}
            </ABtnPrimary>)
        }
      </ContBtnPlan>

      <Price
        color={type === 1 ? GlobalColors.colorPrimary : GlobalColors.colorLight}
      >
        ${price}
        <PriceDesc
          color={type === 1 ? GlobalColors.colorPrimary : GlobalColors.colorTextDiscreet}
        > MX/Mes</PriceDesc>
      </Price>

      <PriceDesc
        color={type === 1 ? GlobalColors.colorPrimary : GlobalColors.colorTextDiscreet}
      >
        (IVA Incluido)
      </PriceDesc>
      </ContaierButton>
    </ContCardPrice>
  );
};
