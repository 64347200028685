import styled, { createGlobalStyle } from 'styled-components'
import {GlobalBackgroundColors, GlobalColors, GlobalFonts} from '../../styles/GlobalStyles';

export const StylesCard = createGlobalStyle`
  .card-primary{
    background-color: ${GlobalBackgroundColors.bgPrimary};
  }
  .card-secondary{
    background-color: ${GlobalBackgroundColors.bgTertiary};
  }
`
export const ContCircleUp = styled.div`
  position: absolute;
  width: 100%;
  height: 120px;
  top: ${props => props.top};
  margin-left: 0px;
  background-color: ${props => props.bgColor};
  border-bottom-left-radius: 80%;
  border-bottom-right-radius: 80%;
  opacity: ${props => props.opacity};
  z-index: 0;
  
  @media(min-width: 300px) and (max-width: 1023px){
    top: 0;
  }
  @media(min-width: 1024px) and (max-width: 3000px){
    top: ${props => props.top}; 
  }
  
  @media(min-width: 1024px){
    //width: calc(100% - 20px);
  }
`
export const ContCardPrice = styled.div`
  width: 100%;
  height: 34rem;
  padding: 10px 18px;
  background-color: ${props => props.bgColor};
  border-radius: 30px;
  box-shadow: 0px 8px 28px -6px rgba(124, 117, 201, 0.2), 0px 18px 88px -4px rgba(124, 117, 201, 0.2);
  display: flex;
  align-items: center;
  justify-items: center;
  flex-direction: column;

  &:hover{
    background-color: ${GlobalBackgroundColors.bgTertiary};
  }

  @media(min-width: 300px) and (max-width: 1023px){
    height: auto;
  }

  @media(min-width: 1024px) and (max-width: 1079px){
    height: 40rem;
  }

  @media(min-width: 1080px) and (max-width: 1157px){
    height: 34rem;
  }
`
export const Title = styled.div`
  margin-bottom: 12px;
  font: 36px ${GlobalFonts.fontExtraBold};
  font-style: normal;
  font-weight: 800;
  line-height: 36px;
  text-align: center;
  color: ${props => props.color};
  z-index: 1;
  
  ${ContCardPrice}:hover & {
    color: ${GlobalColors.colorLight};
  }
`
export const SubTitle = styled.div`
  margin-bottom: 40px;
  font: 18px ${GlobalFonts.fontLight};
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  color: ${props => props.color};
  z-index: 1;
  
  ${ContCardPrice}:hover & {
    color: ${GlobalColors.colorLight};
  }
  
  @media(min-width: 300px) and (max-width: 767px){
    text-align: center;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    text-align: center;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    text-align: center;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    
  }
  @media(min-width: 1920px){
    
  }
`
export const ContInfo = styled.div`
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-items: flex-start;
`
export const Ico = styled.div`
  width: 30px;
  height: 30px;
  &>img{
    width: 100%;
    height: 100%;
  }
`
export const Info = styled.div`
  width: calc(100% - 45px);
  margin-left: 15px;
  font: 16px ${GlobalFonts.fontRegular};
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  color: ${props => props.color};

  ${ContCardPrice}:hover & {
    color: ${GlobalColors.colorLight};
  }
`
export const Price = styled.div`
  margin-bottom: 5px;
  font: 36px ${GlobalFonts.fontBold};
  font-style: normal;
  font-weight: 800;
  line-height: 36px;
  text-align: center;
  color: ${props => props.color};
  display: flex;
  align-items: flex-end;
  justify-content: center;

  ${ContCardPrice}:hover & {
    color: ${GlobalColors.colorLight};
  }
`
export const PriceDesc = styled.div`
  font: 18px ${GlobalFonts.fontLight};
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  color: ${props => props.color};

  ${ContCardPrice}:hover & {
    color: ${GlobalColors.colorTextDiscreet};
  }
`
export const ContaierButton = styled.div`
  position: absolute;
  bottom: -15px;
  left: 0; /* Puedes ajustar esto según la alineación horizontal deseada */
  width: 100%; /* Para que el botón ocupe todo el ancho del card */
  margin-bottom: 25px;
  @media(min-width: 100px) and (max-width: 1023px){
    text-align: center;
    position: relative;
    bottom: 0px;
  }
`

export const ContBtnPlanthree = styled.div`
  margin-top: 15px;
  margin-bottom: 25px;
  align-items: center;
  justify-items: center;
  text-align: center;
  
  &>button{
    width: 290px;
    height: 60px;
  }
`
